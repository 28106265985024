import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "REST Resources",
  "slug": "rest-resources"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`REST Resources`}</h1>
    <h2>{`Summary Resources`}</h2>
    <p>{`When you fetch a list of resources, the response includes a subset of the attributes for that resource. This is the "summary" representation of the resource. (Some attributes are computationally expensive for the API to provide. For performance reasons, the summary representation excludes those attributes. To obtain those attributes, fetch the "detailed" representation.)`}</p>
    <h2>{`Detailed Resources`}</h2>
    <h3>{`Detailed representations`}</h3>
    <p>{`When you fetch an individual resource, the response typically includes all attributes for that resource. This is the "detailed" representation of the resource. (Note that authorization sometimes influences the amount of detail included in the representation.)`}</p>
    <h3>{`Authentication`}</h3>
    <p>{`All requests to enableHR REST API endpoints require authentication. Requests that require authentication will return 404 Not Found, instead of 403 Forbidden, in some places. This is to prevent the accidental leakage of private information to unauthorized users.`}</p>
    <h2>{`Parameters`}</h2>
    <p>{`Many API methods take optional parameters. For GET requests, any parameters not specified as a segment in the path can be passed as an HTTP query string parameter:`}</p>
    <h2>{`HTTP redirects`}</h2>
    <p>{`API uses HTTP redirection where appropriate. Clients should assume that any request may result in a redirection. Receiving an HTTP redirection is not an error and clients should follow that redirect. Redirect responses will have a Location header field which contains the URI of the resource to which the client should repeat the requests.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Status Code`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`301 Permanent redirection.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The URI you used to make the request has been superseded by the one specified in the Location header field. This and all future requests to this resource should be directed to the new URI.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`302, 307 Temporary redirection.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The request should be repeated verbatim to the URI specified in the Location header field but clients should continue to use the original URI for future requests.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Other redirection status codes may be used in accordance with the HTTP 1.1 spec.`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Timestamps`}</h2>
    <p>{`All timestamps return in ISO 8601 format:`}</p>
    <p>{`YYYY-MM-DDTHH:MM:SSZ`}</p>
    <h2>{`Hypermedia`}</h2>
    <p>{`All resources may have one or more `}{`*`}{`_`}{`url properties linking to other
resources. These are meant to provide explicit URLs so that proper API
clients don't need to construct URLs on their own. It is highly
recommended that API clients use these. Doing so will make future
upgrades of the API easier for developers. All URLs are expected to be
proper RFC 6570 URI templates.`}</p>
    <h2>{`Pagination`}</h2>
    <p>{`Requests that return multiple items will be paginated to 100 items by default.`}</p>
    <p>{`Listing endpoints use cursor-based pagination. A cursor is a string that
points to a location in the result set. With cursor-based pagination,
there is no fixed concept of "pages" in the result set, so you can't
navigate to a specific page. Instead, you can traverse the results by
using the before or after parameters.`}</p>
    <p>{`You can specify the number of items returned with the first or last
parameters.`}</p>
    <h2>{`HTTP Status Codes`}</h2>
    <p>{`enableHR REST APIs use the Status-Line part of an HTTP response message to inform clients of their request’s overarching result.`}</p>
    <p>{`RFC 2616 defines the Status-Line syntax as shown below:`}</p>
    <p><inlineCode parentName="p">{`Status-Line = HTTP-Version SP Status-Code SP Reason-Phrase CRLF`}</inlineCode></p>
    <p>{`HTTP defines these standard status codes that can be used to convey the results of a client’s request. The status codes are divided into the five categories.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Category`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2xx:`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Success – Indicates that the client’s request was accepted successfully.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`4xx:`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Client Error – This category of error status codes points the finger at clients.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`5xx:`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Server Error – The server takes responsibility for these error status codes.`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`2xx Status Codes`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`STATUS CODE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`200 OK`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Indicates that request has succeeded.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`201 Created`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Indicates that request has succeeded and a new resource has been created as a result.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`202 Accepted`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Indicates that the request has been received but not completed yet. It is typically used in log running requests and batch processing.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`204 No Content`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The server successfully processed the request, and is not returning any content.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`REST Specific Status Codes`}</h3>
    <h3>{`200 (OK)`}</h3>
    <p>{`It indicates that the REST API successfully carried out whatever action the client requested and that no more specific code in the 2xx series is appropriate.`}</p>
    <p>{`Unlike the 204 status code, a 200 response should include a response body. The information returned with the response is dependent on the method used in the request, for example:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Method`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`GET`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`an entity corresponding to the requested resource is sent in the response;`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`HEAD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`the entity-header fields corresponding to the requested resource are sent in the response without any message-body;`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`POST`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`an entity describing or containing the result of the action;`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`TRACE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`an entity containing the request message as received by the end server.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`201 (Created)`}</h3>
    <p>{`A REST API responds with the 201 status code whenever a resource is created inside a collection. There may also be times when a new resource is created as a result of some controller action, in which case 201 would also be an appropriate response.`}</p>
    <p>{`The newly created resource can be referenced by the URI(s) returned in the entity of the response, with the most specific URI for the resource given by a Location header field.`}</p>
    <p>{`The origin server MUST create the resource before returning the 201 status code. If the action cannot be carried out immediately, the server SHOULD respond with a 202 (Accepted) response instead.`}</p>
    <h3>{`202 (Accepted)`}</h3>
    <p>{`A 202 response is typically used for actions that take a long while to process. It indicates that the request has been accepted for processing, but the processing has not been completed. The request might or might not be eventually acted upon, or even maybe disallowed when processing occurs.`}</p>
    <p>{`Its purpose is to allow a server to accept a request for some other process (perhaps a batch-oriented process that is only run once per day) without requiring that the user agent’s connection to the server persist until the process is completed.`}</p>
    <p>{`The entity returned with this response SHOULD include an indication of the request’s current status and either a pointer to a status monitor (job queue location) or some estimate of when the user can expect the request to be fulfilled.`}</p>
    <h3>{`204 (No Content)`}</h3>
    <p>{`The 204 status code is usually sent out in response to a PUT, POST, or DELETE request when the REST API declines to send back any status message or representation in the response message’s body.`}</p>
    <p>{`An API may also send 204 in conjunction with a GET request to indicate that the requested resource exists, but has no state representation to include in the body.`}</p>
    <p>{`If the client is a user agent, it SHOULD NOT change its document view from that which caused the request to be sent. This response is primarily intended to allow input for actions to take place without causing a change to the user agent’s active document view. However, any new or updated metainformation SHOULD be applied to the document currently in the user agent’s dynamic view.`}</p>
    <p>{`The 204 response MUST NOT include a message-body and thus is always terminated by the first empty line after the header fields.`}</p>
    <h2>{`4xx Status Code`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`STATUS CODE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`400 Bad Request`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The request could not be understood by the server due to incorrect syntax. The client SHOULD NOT repeat the request without modifications.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`401 Unauthorized`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Indicates that the request requires user authentication information. The client MAY repeat the request with a suitable Authorization header field`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`403 Forbidden`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unauthorized request. The client does not have access rights to the content. Unlike 401, the client’s identity is known to the server.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`404 Not Found`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The server can not find the requested resource.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`400 (Bad Request)`}</h3>
    <p>{`400 is the generic client-side error status, used when no other 4xx error code is appropriate. Errors can be like malformed request syntax, invalid request message parameters, or deceptive request routing etc.`}</p>
    <p>{`The client SHOULD NOT repeat the request without modifications.`}</p>
    <h3>{`401 (Unauthorized)`}</h3>
    <p>{`A 401 error response indicates that the client tried to operate on a protected resource without providing the proper authorization. It may have provided the wrong credentials or none at all. The response must include a WWW-Authenticate header field containing a challenge applicable to the requested resource.`}</p>
    <p>{`The client MAY repeat the request with a suitable Authorization header field. If the request already included Authorization credentials, then the 401 response indicates that authorization has been refused for those credentials. If the 401 response contains the same challenge as the prior response, and the user agent has already attempted authentication at least once, then the user SHOULD be presented the entity that was given in the response, since that entity might include relevant diagnostic information.`}</p>
    <h3>{`403 (Forbidden)`}</h3>
    <p>{`A 403 error response indicates that the client’s request is formed correctly, but the REST API refuses to honor it, i.e., the user does not have the necessary permissions for the resource. A 403 response is not a case of insufficient client credentials; that would be 401 (“Unauthorized”).`}</p>
    <p>{`Authentication will not help, and the request SHOULD NOT be repeated. Unlike a 401 Unauthorized response, authenticating will make no difference.`}</p>
    <h3>{`404 (Not Found)`}</h3>
    <p>{`The 404 error status code indicates that the REST API can’t map the client’s URI to a resource but may be available in the future. Subsequent requests by the client are permissible.`}</p>
    <p>{`No indication is given of whether the condition is temporary or permanent. The 410 (Gone) status code SHOULD be used if the server knows, through some internally configurable mechanism, that an old resource is permanently unavailable and has no forwarding address. This status code is commonly used when the server does not wish to reveal exactly why the request has been refused, or when no other response is applicable.`}</p>
    <h2>{`5xx Status Code`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`STATUS CODE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`500 Internal Server Error`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The server encountered an unexpected condition which prevented it from fulfilling the request.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`501 Not Implemented`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The HTTP method is not supported by the server and cannot be handled.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`503 Service Unavailable`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The server is not ready to handle the request.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`500 (Internal Server Error)`}</h3>
    <p>{`500 is the generic REST API error response. Most web frameworks automatically respond with this response status code whenever they execute some request handler code that raises an exception.`}</p>
    <p>{`A 500 error is never the client’s fault, and therefore, it is reasonable for the client to retry the same request that triggered this response and hope to get a different response.`}</p>
    <p>{`The API response is the generic error message, given when an unexpected condition was encountered and no more specific message is suitable.`}</p>
    <h3>{`503 Service Unavailable`}</h3>
    <p>{`The server cannot handle the request (because it is overloaded or down for maintenance). Generally, this is a temporary state.`}</p>
    <h1>{`HTTP Methods`}</h1>
    <p>{`REST APIs enable you to develop any kind of web application having all possible CRUD (create, retrieve, update, delete) operations.`}</p>
    <p>{`REST guidelines suggest using a specific HTTP method on a particular type of call made to the server (though technically it is possible to violate this guideline, yet it is highly discouraged).`}</p>
    <p>{`Use below-given information to find a suitable HTTP method for the action performed by API.`}</p>
    <h2>{`HTTP GET`}</h2>
    <p>{`Use GET requests to retrieve resource representation/information only – and not to modify it in any way. As GET requests do not change the state of the resource, these are said to be safe methods. Additionally, GET APIs should be `}<em parentName="p">{`idempotent`}</em>{`, which means that making multiple identical requests must produce the same result every time until another API (POST or PUT) has changed the state of the resource on the server.`}</p>
    <p>{`If the Request-URI refers to a data-producing process, it is the produced data which shall be returned as the entity in the response and not the source text of the process, unless that text happens to be the output of the process.`}</p>
    <p>{`For any given HTTP GET API, if the resource is found on the server, then it must return HTTP response code 200 (OK) – along with the response body, which is JSON content (due to their platform-independent nature).`}</p>
    <p>{`In case resource is NOT found on server then it must return HTTP response code 404 (NOT FOUND).`}</p>
    <p>{`Similarly, if it is determined that GET request itself is not correctly formed then server will return HTTP response code 400 (BAD REQUEST).`}</p>
    <h2>{`HTTP POST`}</h2>
    <p>{`Use POST APIs to create new subordinate resources, e.g., a file is subordinate to a directory containing it or a row is subordinate to a database table. When talking strictly in terms of REST, POST methods are used to create a new resource into the collection of resources.`}</p>
    <p>{`Ideally, if a resource has been created on the origin server, the response SHOULD be HTTP response code 201 (Created) and contain an entity which describes the status of the request and refers to the new resource, and a Location header.`}</p>
    <p>{`Many times, the action performed by the POST method might not result in a resource that can be identified by a URI. In this case, either HTTP response code 200 (OK) or 204 (No Content) is the appropriate response status.`}</p>
    <p>{`Responses to this method are not cacheable, unless the response includes appropriate Cache-Control or Expires header fields.`}</p>
    <p>{`Please note that POST is neither `}<inlineCode parentName="p">{`safe`}</inlineCode>{` nor `}<em parentName="p">{`idempotent`}</em>{`, and invoking two identical POST requests will result in two different resources containing the same information (except resource ids).`}</p>
    <h2>{`HTTP PUT`}</h2>
    <p>{`Use PUT APIs primarily to update existing resource (if the resource does not exist, then API may decide to create a new resource or not). If a new resource has been created by the PUT API, the origin server MUST inform the user agent via the HTTP response code 201 (Created) response and if an existing resource is modified, either the 200 (OK) or 204 (No Content) response codes SHOULD be sent to indicate successful completion of the request.`}</p>
    <p>{`If the request passes through a cache and the Request-URI identifies one or more currently cached entities, those entries SHOULD be treated as stale. Responses to this method are not cacheable.`}</p>
    <p>{`The difference between the POST and PUT APIs can be observed in request URIs. POST requests are made on resource collections, whereas PUT requests are made on a single resource.`}</p>
    <h2>{`HTTP DELETE`}</h2>
    <p>{`As the name applies, DELETE APIs are used to delete resources (identified by the Request-URI).`}</p>
    <p>{`A successful response of DELETE requests SHOULD be HTTP response code 200 (OK) if the response includes an entity describing the status, 202 (Accepted) if the action has been queued, or 204 (No Content) if the action has been performed but the response does not include an entity.`}</p>
    <p>{`DELETE operations are `}<em parentName="p">{`idempotent`}</em>{`. If you DELETE a resource, it’s removed from the collection of resources. Repeatedly calling DELETE API on that resource will not change the outcome – however, calling DELETE on a resource a second time will return a 404 (NOT FOUND) since it was already removed. Some may argue that it makes the DELETE method `}<em parentName="p">{`non-idempotent`}</em>{`. It’s a matter of discussion and personal opinion.`}</p>
    <p>{`If the request passes through a cache and the Request-URI identifies one or more currently cached entities, those entries SHOULD be treated as stale. Responses to this method are not cacheable.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      